import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Footer from "../components/Footer";
import { API_URL } from "../constants";
import Hero from "../components/Hero";
// import Section1 from "../components/Section1";
// import Section2 from "../components/Section2";
// import Section3 from "../components/Section3";
import Section4 from "../components/Section4";

import SignupThanks from "../components/SignupThanks";
import SignupForm from "../components/SignupForm";
import Submitting from "../components/Submitting";

import imgLogo from "../assets/logo-1.png";

export default function Entry() {
  // const params = useParams();
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  const [invalid, setInvalid] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [signedUp, setSignedUp] = useState(false);

  useEffect(() => {
    const signedUpStore = localStorage.getItem("aifund_s");
    if (signedUpStore) {
      setSignedUp(true);
    }
  }, []);

  // useEffect(() => {
  //   const func = async () => {
  //     const token = params["*"];

  //     const response = await fetch(
  //       `${API_URL}/verify-qr?t=${encodeURIComponent(token)}`
  //     );

  //     const responseData = await response.json();

  //     setLoading(false);

  //     if (!responseData?.verified) {
  //       navigate("/");
  //     }
  //   };

  //   func();
  // }, [navigate, params]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const firstName = data.get("firstname");
    const lastName = data.get("lastname");
    const email = data.get("email");
    const website = data.get("website");
    const handle = data.get("handle");
    const referral = data.get("referral");
    // const interestInvestDirectly = data.get("invest_directly") !== null;
    // const interestTokenSwap = data.get("token_swap") !== null;
    // const interestBoth = data.get("both") !== null;

    setInvalid([]);

    const invalids = [];

    if (!firstName.length) {
      invalids.push("firstname");
    }

    if (!lastName.length) {
      invalids.push("lastname");
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      invalids.push("email");
    }

    if (invalids.length) {
      setInvalid(invalids);
      return;
    }

    const payload = {
      firstName,
      lastName,
      email,
      website,
      handle,
      referral,
      // interestInvestDirectly,
      // interestTokenSwap,
      // interestBoth,
    };

    setSubmitting(true);

    fetch(`${API_URL}/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        p: payload,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) setError(true);
        localStorage.setItem("aifund_s", true);
        setSignedUp(true);
        setError(false);
        setSubmitting(false);
        setSuccess(true);
        document.documentElement.scrollTo({
          top: 0,
          left: 0,
        });
      })
      .catch(() => {
        setError(true);
      });
  };

  // if (loading) return null;

  return (
    <>
      <Hero>
        <Box
          component="img"
          sx={{
            display: "block",
            mx: "auto",
            mt: { xs: 17, sm: 24 },
            mb: 4,
            maxWidth: "100%",
          }}
          alt="MFX Index Token"
          src={imgLogo}
          className="fade_in"
          onLoad={(ev) => {
            ev.target.classList.add("fade_in--loaded");
          }}
        />
      </Hero>

      {/* {!signedUp && <Section1 />} */}
      <Section4 />

      <Container maxWidth={false} sx={{ background: "#d1eaf6" }}>
        <Container maxWidth="sm" sx={{ py: 5 }}>
          {signedUp && <SignupThanks />}

          {submitting && <Submitting />}

          {!success && !submitting && !signedUp && (
            <SignupForm
              invalid={invalid}
              onSubmit={handleSubmit}
              error={error}
            />
          )}
        </Container>
      </Container>

      <Footer />
    </>
  );
}
