import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";

export default function SignupForm({ onSubmit, invalid, error }) {
  const theme = useTheme();

  return (
    <Container maxWidth="sm" sx={{ pt: 1, pb: 15 }}>
      <h3 style={{ color: "#222" }} align="center">
        Interested?
      </h3>

      <Typography align="center" gutterBottom sx={{ mb: 2 }}>
        We are seeking early staged, well positioned projects at the
        intersection of decentralized technology and AI to join our community.
      </Typography>
      <Typography align="center">
        If you share our vision for the future of decentralized AI and would
        like to become part of our community through a token swap, please fill
        out this form to start the discussion:
      </Typography>
      <Box
        component="form"
        onSubmit={onSubmit}
        noValidate
        sx={{ mt: 1, background: "#fff", p: 3, borderRadius: 5, my: 3 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="firstname"
          label="First Name"
          name="firstname"
          sx={{
            "& .MuiInputBase-input": {
              borderRadius: "4px",
              borderRight: invalid.find((f) => f === "firstname")
                ? `5px solid ${theme.palette.error.main}`
                : "none",
            },
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="lastname"
          label="Last Name"
          name="lastname"
          sx={{
            "& .MuiInputBase-input": {
              borderRadius: "4px",
              borderRight: invalid.find((f) => f === "lastname")
                ? `5px solid ${theme.palette.error.main}`
                : "none",
            },
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          id="website"
          label="Company or Org website (if applicable)"
          name="website"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          sx={{
            "& .MuiInputBase-input": {
              borderRadius: "4px",
              borderRight: invalid.find((f) => f === "email")
                ? `5px solid ${theme.palette.error.main}`
                : "none",
            },
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          id="handle"
          label="Preferred social media handle"
          name="handle"
        />
        <TextField
          margin="normal"
          fullWidth
          id="referral"
          label="How did you hear about us?"
          name="referral"
        />
        {/* <FormGroup>
          <p>Interested in</p>
          <FormControlLabel
            control={<Checkbox />}
            name="invest_directly"
            label="Investing directly into MFX"
          />
          <FormControlLabel
            name="token_swap"
            control={<Checkbox />}
            label="Token swap"
          />
          <FormControlLabel name="both" control={<Checkbox />} label="Both" />
        </FormGroup> */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, height: 50 }}
        >
          Submit
        </Button>
      </Box>

      {invalid.length ? (
        <Box>
          <Typography
            component="p"
            variant="p"
            sx={{ color: theme.palette.error.main }}
            align="center"
          >
            Complete all required fields
          </Typography>
        </Box>
      ) : null}

      {error && (
        <Box>
          <Typography
            component="p"
            variant="p"
            sx={{ color: theme.palette.error.main }}
            align="center"
          >
            An error has occurred
          </Typography>
        </Box>
      )}
    </Container>
  );
}
