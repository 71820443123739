import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import CheckIcon from "@mui/icons-material/Check";
import { API_URL } from "../constants";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Admin() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [removing, setRemoving] = React.useState(false);
  const [qrs, setQRs] = React.useState([]);
  const [signups, setSignups] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const getQRs = async () => {
    const token = localStorage.getItem("aifund_t");
    const response = await fetch(
      `${API_URL}/get-qr?t=${encodeURIComponent(token)}&p=${encodeURIComponent(
        window.location.origin
      )}`
    );
    const responseData = await response.json();
    setQRs(responseData.results);
  };

  const getSignups = async () => {
    const token = localStorage.getItem("aifund_t");
    const response = await fetch(
      `${API_URL}/get-signups?t=${encodeURIComponent(token)}`
    );
    const responseData = await response.json();
    setSignups(responseData.results);
  };

  useEffect(() => {
    const func = async () => {
      const token = localStorage.getItem("aifund_t");
      const response = await fetch(
        `${API_URL}/verify-auth?t=${encodeURIComponent(token)}`
      );
      const responseData = await response.json();

      setLoading(false);

      if (!responseData?.verified) {
        navigate("/login");
      }
    };

    func();
    getQRs();
    getSignups();
  }, [navigate]);

  const handleGenerate = async () => {
    const token = localStorage.getItem("aifund_t");
    await fetch(`${API_URL}/generate-qr`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: token,
        p: encodeURIComponent(window.location.origin),
      }),
    });
    getQRs();
  };

  const handleLogout = async () => {
    localStorage.removeItem("aifund_t");
    navigate("/login");
  };

  const handleRemoveQR = async (uuid) => {
    setRemoving(true);
    await fetch(`${API_URL}/del-qr`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ u: uuid, t: localStorage.getItem("aifund_t") }),
    });
    setRemoving(false);
    getQRs();
  };

  const handleRemoveSignup = async (uuid) => {
    setRemoving(true);
    await fetch(`${API_URL}/del-signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ u: uuid, t: localStorage.getItem("aifund_t") }),
    });
    setRemoving(false);
    getSignups();
  };

  const handleScroll = (e) => {
    setScrollPosition(e.target.scrollLeft);
  };

  if (loading) return null;

  return (
    <Container component="main" maxWidth="md">
      <Box mt={8}>
        <Button variant="outlined" onClick={handleLogout} sx={{ mr: 2 }}>
          Log out
        </Button>
        {/* <Button variant="contained" onClick={handleGenerate}>
          Generate
        </Button> */}
      </Box>
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/*
        <h4>QR Codes</h4>
        {qrs.length === 0 ? (
          <Typography color="#666">No QR Codes</Typography>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>QR</TableCell>
                <TableCell>Link</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {qrs.map((row) => (
                <TableRow key={row.uuid}>
                  <TableCell>
                    <Box
                      component="img"
                      sx={{ width: 40, cursor: "pointer" }}
                      src={row.img}
                      alt="QR"
                      onClick={handleOpen}
                    />
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={modalStyle}>
                        <Box
                          sx={{ width: "100%" }}
                          component="img"
                          src={row.img}
                          alt="QR"
                          onClick={handleClose}
                        />
                      </Box>
                    </Modal>
                  </TableCell>
                  <TableCell>
                    <Box
                      component="a"
                      href={row.url}
                      sx={{
                        display: "block",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: { xs: "120px", sm: "200px", md: "400px" },
                      }}
                    >
                      {row.url}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      disabled={removing}
                      onClick={() => handleRemoveQR(row.uuid)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )} */}

        <h4>Signups</h4>
        {signups.length === 0 ? (
          <Typography color="#666">No QR Codes</Typography>
        ) : (
          <div
            style={{
              overflowX: "auto",
              boxShadow:
                scrollPosition < 50
                  ? "inset -3px 0 3px 0 rgba(0, 0, 0, 0.1)"
                  : "none",
            }}
            onScroll={handleScroll}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                    First Name
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Website</TableCell>
                  <TableCell>Handle</TableCell>
                  {/* <TableCell>Referral</TableCell>
                  <TableCell>
                    <Box sx={{ whiteSpace: "nowrap" }}>Invest Directly</Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ whiteSpace: "nowrap" }}>Token Swap</Box>
                  </TableCell>
                  <TableCell>Both</TableCell> */}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {signups.map((row) => (
                  <TableRow key={row.uuid}>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>
                      <a href={`mailto=${row.email}`}>{row.email}</a>
                    </TableCell>
                    <TableCell>{row.website}</TableCell>
                    <TableCell>{row.handle}</TableCell>
                    <TableCell>{row.referral}</TableCell>
                    {/* <TableCell align="center">
                      {row.interestInvestDirectly && <CheckIcon />}
                    </TableCell>
                    <TableCell align="center">
                      {row.interestTokenSwap && <CheckIcon />}
                    </TableCell>
                    <TableCell align="center">
                      {row.interestBoth && <CheckIcon />}
                    </TableCell> */}
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        disabled={removing}
                        onClick={() => handleRemoveSignup(row.uuid)}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </Box>
    </Container>
  );
}
