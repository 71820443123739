import Box from "@mui/material/Box";
import LoopIcon from "@mui/icons-material/Loop";

export default function Submitting() {
  return (
    <Box
      sx={{
        display: "flex",
        height: "738.5px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoopIcon
        sx={{
          fontSize: 48,
          animation: "spin 2s linear infinite",
          "@keyframes spin": {
            "0%": {
              transform: "rotate(360deg)",
            },
            "100%": {
              transform: "rotate(0deg)",
            },
          },
        }}
      />
    </Box>
  );
}
