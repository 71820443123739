import React from "react";
import { Box, Container } from "@mui/material";

export default function Hero({ children, color }) {
  return (
    <Container disableGutters maxWidth={false} className="hero">
      <Box
        component="section"
        sx={{
          height: { xs: 300, sm: 500 },
          position: "relative",
        }}
      >
        <Box
          // className="animate__animated animate__fadeIn"
          sx={{
            display: "flex",
            position: "relative",
            color: "#fff",
            zIndex: 3,
            margin: "auto",
            textAlign: "center",
            // px: { xs: 2, sm: 0 },
            pt: { xs: 0, sm: 5 },
            maxWidth: 300,
            justifyContent: "center",
          }}
        >
          {children}
        </Box>
        <Box
          className="bg-1"
          sx={{
            bottom: 0,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
          }}
        />
      </Box>
    </Container>
  );
}
