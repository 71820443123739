import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// import Root from "./pages/Root";
import Admin from "./pages/Admin";
import Entry from "./pages/Entry";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";

const defaultTheme = createTheme();

function App() {
  let location = useLocation();

  useEffect(() => {
    const anchor = window.location.hash.slice(1);
    if (anchor) {
      const anchorEl = document.getElementById(anchor);
      if (anchorEl) {
        anchorEl.scrollIntoView();
      }
    }
  }, [location]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <Routes>
        <Route path="/" element={<Entry />} />
        <Route path="/admin" element={<Admin />} />
        {/* <Route path="/entry/*" element={<Entry />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
