import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import art1 from "../assets/art-1.png";
import art2 from "../assets/art-2.png";
import art3 from "../assets/art-3.png";

export default function Section4() {
  return (
    <>
      <Container maxWidth={false} sx={{ background: "#fff" }}>
        <Container maxWidth="sm" sx={{ py: 5 }}>
          <Grid container>
            <Grid item xs={12} sm={6} alignContent="center">
              <Box
                component="img"
                sx={{
                  display: "block",
                  mx: "auto",
                  mb: { xs: 4, sm: 0 },
                  maxWidth: "100%",
                  width: 200,
                }}
                alt="Incentivization"
                src={art1}
                className="fade_in"
                onLoad={(ev) => {
                  ev.target.classList.add("fade_in--loaded");
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                AITreasury is an organization designed to align promising
                projects in the decentralized AI ecosystem through economic
                incentives. We select the strongest positioned early-stage
                projects and help them grow together. Our goal is to align the
                decentralized AI ecosystem through cooperative economic
                incentivization.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container maxWidth={false} sx={{ background: "#dcf2f3" }}>
        <Container maxWidth="sm" sx={{ py: 5 }}>
          <Grid container flexDirection={{ xs: "column-reverse", sm: "row" }}>
            <Grid item xs={12} sm={6}>
              <Typography>
                One way this alignment is achieved is through arranging token
                swaps of AITreasury tokens and the tokens of the partner
                projects, creating win-win scenarios for our community members.
                When one member succeeds, all parties in the community succeed,
                where emergently cooperative behavior is enabled.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} alignContent="center">
              <Box
                component="img"
                sx={{
                  display: "block",
                  mx: "auto",
                  mb: { xs: 4, sm: 0 },
                  maxWidth: "100%",
                  width: 200,
                }}
                alt="Success"
                src={art2}
                className="fade_in"
                onLoad={(ev) => {
                  ev.target.classList.add("fade_in--loaded");
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container maxWidth={false} sx={{ background: "#fff" }}>
        <Container maxWidth="sm" sx={{ py: 5 }}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box
                component="img"
                sx={{
                  display: "block",
                  mx: "auto",
                  mb: { xs: 4, sm: 0 },
                  maxWidth: "100%",
                  width: 200,
                }}
                alt="Ecosystem"
                src={art3}
                className="fade_in"
                onLoad={(ev) => {
                  ev.target.classList.add("fade_in--loaded");
                }}
              />
            </Grid>
            <Grid item container xs={12} sm={6} alignContent="center">
              <Typography>
                Our Treasury is an economic engine that supports our entire
                ecosystem.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}
