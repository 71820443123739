import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

export default function SignupThanks() {
  return (
    <Container maxWidth={false}>
      <Container maxWidth="xs" sx={{ py: 42 }}>
        <Box
          sx={{
            display: "flex",
            // background: "#fff",
            justifyContent: "center",
            alignItems: "center",
            // borderRadius: 5,
          }}
          p={2}
        >
          <CheckCircleOutlinedIcon
            sx={{ fontSize: 28, color: "#111", mr: 1 }}
          />
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#111",
            }}
          >
            Thank you for your interest
          </Typography>
        </Box>
      </Container>
    </Container>
  );
}
